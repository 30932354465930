import React from "react"
import { Item } from "semantic-ui-react"
import SEO from "../components/seo"
import "./general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../defines";

const Title = "Vegan Products";

const data = require("../data/vegan-products-sites.json");

const ProductSites = ({ mobile }) => {
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={{ ...vizBackgroundStyle(mobile), width: mobile ? "100%" : "50%" }}>
                    <div>
                        <Item.Group divided relaxed>
                            {
                                data.sites.map(details => (
                                    <Item key={details.symbol}>
                                        <Item.Image size="small" src={`/products/${details.name.replace(/\s/g, "").toLowerCase()}.png`} />
                                        <Item.Content>
                                            <Item.Header>{details.name}</Item.Header>
                                            <Item.Meta>
                                                {
                                                        <>
                                                            <span>See at</span>
                                                            <span>{<a href={details.link}>{details.link}</a>}
                                                            </span>
                                                        </>
                                                }
                                            </Item.Meta>
                                            <Item.Description>
                                                {details.synopsis}
                                                <div style={{ marginBottom: 5 }}>
                                                    {details.description}
                                                </div>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                ))
                            }
                        </Item.Group>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductSites;
